<template>
  <div id="app" class="font-cairo">
    <!-- <img class="mt-10 mx-auto" alt="Future logo" src="./assets/logo.png"> -->
    <Calculator/>
  </div>
</template>

<script>
import Calculator from './components/Calculator.vue'

export default {
  name: 'App',
  components: {
    Calculator
  }
}
</script>

<style>
</style>
