<template>
  <div class="container mx-auto py-10 my-10 bg-primary">

    <div class="grid grid-cols-2 gap-4">

      <div class="col-span-2">
        <p class="text-center text-3xl text-white">تصنيف حجم الأوقاف الاستثمارية</p>
      </div>

      <div>
        <input type="number" v-model="investmentAsset"
          class="mx-10 mt-1 text-right block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
          placeholder="">
      </div>
      <div class="px-10 text-2xl text-center text-white">
        حجم الأصول الأستثمارية
      </div>

      <div>
        <input type="number" v-model="totalRevenue"
          class="mx-10 mt-1 text-right block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0">
      </div>
      <div class="px-10 text-2xl text-center text-white">
        إجمالي الإيرادات
      </div>

      <div>
        <input type="number" v-model="ratioAsset"
          class="mx-10 mt-1 text-right block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
          disabled>
      </div>
      <div class="px-10 text-2xl text-center text-white">
        نسبة الأيرادات من حجم الأصول
      </div>

      <div class="px-10 text-2xl text-center text-white">
        {{this.outputStandard}}
      </div>
      <div class="px-10 text-2xl text-center text-white">
        ناتج المعيار
      </div>

      <div class="px-10 text-2xl text-center text-white">
        {{this.category}}
      </div>
      <div class="px-10 text-2xl text-center text-white">
        التصنيف
      </div>

    </div>

  </div>
</template>

<script>
  export default {
    name: 'Calculator',
    props: {},
    data() {
      return {
        investmentAsset: '',
        // ratioAsset: '',
        totalRevenue: '',
      }
    },

    mounted() {},

    computed: {

      ratioAsset: function () {
        let ratioAsset
        ratioAsset = (this.totalRevenue / this.investmentAsset) * 100
        return ratioAsset.toFixed(2)
      },

      outputStandard: function (){
        return ((this.investmentAsset * 20) / 100) + ((this.totalRevenue * 80) / 100)
      },

      category: function (){
        
        if (this.outputStandard <= 10000000) {  // 10
          return 'متناهي الصغر'
        }
        if (this.outputStandard >= 10000001 && this.outputStandard <= 50000000) { // 10 - 50
          return 'صغير'
        }
        if (this.outputStandard >= 50000001 && this.outputStandard <= 100000000) { // 50 - 10
          return 'متوسط'
        }
        if (this.outputStandard >= 100000001 && this.outputStandard <= 500000000) { // 100 - 500
          return 'كبير'
        }
        if (this.outputStandard >= 500000001 && this.outputStandard <= 1000000000) { // 500 - 1000
          return 'كبير جدا'
        }
        if (this.outputStandard >= 1000000001) { // 1000
          return 'ميجا'
        }
        return ''

      }

    },

    methods: {

      submit() {

      },

    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>